import {LockerCarrier} from './locker-carrier';
import {LANGUAGE} from '../../common/enums';
import {TIME_ZONES} from '../../common/common_enums';
import {Location} from "../location/location";

export enum APP_STATE {
    ACTIVE = 'ACTIVE',
    BACKGROUND = 'BACKGROUND'
}

export enum LOCKER_CODES_TYPE {
    ACCESS_CODES = 'accessCodes',
    PRODUCT_RETURN_PICKUP_CODE = 'productReturnPickupCode',
    RETURN_PICKUP_CODE = 'returnPickupCode'
}

export interface Locker {
    _id?: string;
    name?: string;
    lockerType?: string;
    lockerNumber?: string;
    companyId?: any;
    validPostalCodes?: string;
    carriers?: Array<LockerCarrier>;
    locationId?: Location;
    cabinets?: any;
    totalReceived?: number;
    totalShipped?: number;
    totalSlots?: number;
    isMultiBuilding?: string;
    isAutoUpdate?: string;
    adminEmails?: Array<string>;
    defaultLanguage?: LANGUAGE;
    accessCodes?: Array<string>;
    timezone?: TIME_ZONES;
    lastKnownVersion?: string;
    returnPickupCode?: string;
    productReturnPickupCode?: string;
    middlewareVersion?: string;
    appState?: APP_STATE;
    isDropOffEnabled?: boolean;
    isOutboundEnabled?: boolean;
    lockerProfile?: object;
    slotSizes?: Array<string> | [];
    shipmentReminderTime?: number;
}

export interface SlotDetails {
    slotType: string;
    slotNumber: string;
    isUpperSlot: boolean;
}

export enum SKIN {
    ARTITALIA = 'ARTITALIA',
    BROWNS = 'BROWNS',
    BUDROOM = 'BUDROOM',
    CAL = 'CAL',
    CANNACAB = 'CANNACAB',
    CPC = 'CPC',
    DENIM = 'DENIM',
    EMJAYS = 'EMJAYS',
    PARCELPORT = 'PARCELPORT',
    PURO = 'PURO',
    RBC = 'RBC',
    RETAIL = 'RETAIL',
    STUDIO = 'STUDIO',
    UPS = 'UPS',
    BARRIE = 'BARRIE',
    POPCLEANER = 'POPCLEANER'
}
